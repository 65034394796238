.pad-top-s {
	padding-top: 2px;
}

.pad-top-m {
	padding-top: 6px;
}
.pad-top-l {
	padding-top: 12px;
}

.pad-top-xl {
	padding-top: 18px;
}

.pad-bottom-s {
	padding-bottom: 2px;
}

.pad-bottom-m {
	padding-bottom: 6px;
}

.pad-bottom-l {
	padding-bottom: 12px;
}

.pad-bottom-xl {
	padding-bottom: 18px;
}

.margin-top-s {
	margin-top: 2px;
}

.margin-top-m {
	margin-top: 6px;
}

.margin-top-l {
	margin-top: 12px;
}

.margin-top-xl {
	margin-top: 18px;
}

.margin-bottom-s {
	margin-bottom: 2px;
}

.margin-bottom-m {
	margin-bottom: 6px;
}

.margin-bottom-l {
	margin-bottom: 12px;
}

.margin-bottom-xl {
	margin-bottom: 18px;
}
