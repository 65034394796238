.fade {
	-webkit-transition: opacity 0.2s ease-in;
	-moz-transition: opacity 0.2s ease-in;
	-o-transition: opacity 0.2s ease-in;
	-ms-transition: opacity 0.2s ease-in;
	transition: opacity 0.2s ease-in;
}

.hide {
	opacity: 0;
}

.show {
	opacity: 1;
}
