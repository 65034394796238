.counter, .counter-standalone {
	pointer-events: none;
	background: $color4;
	color: $black;
	height: 16px;
	min-width: 16px;
	font-size: 12px;
	font-weight: bold;
	line-height: 12px;
	border-radius: 8px;
	padding: 2px;
	transition: 0.2s opacity, 0.2s background-color;
	text-align: center;

	&.withtitle {
		pointer-events: initial;
		cursor: help;
	}

	&.hide {
		opacity: 0;
		&.withtitle {
			pointer-events: none;
			cursor: inherit;
		}
	}

	&.alert {
		background: $color5;
	}

	&.highlight {
		background: $color6;
	}

	&.small {
		height: 8px;
		min-width: 8px;
		border-radius: 4px;
		top: 0px;
		right: 0px;
	}

	&.indent  {
		top: 4px;
		right: 4px;
	}
}

.counter {
	position: absolute;
	top: -3px;
	right: -3px;
}


