@import '~scss/variables';

body {
	overflow: hidden;
	position: relative;
}

.screen {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
}
