
input, textarea, .input--textarea {
	background: $color1-lightest;
	font-size: $font-size;
	font-family: $font-text;
	border: none;
	padding: 8px;
	border-radius: 4px;
	color: $color3;
	width: 100%;

	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $color1-placeholder-light;
		opacity: 1; /* Firefox */
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $color1-placeholder-light;
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $color1-placeholder-light;
	}
}

input, textarea {
	&:focus {
		outline: 0;
		box-shadow: 0 0 4px 1px $color2;
	}
}

input[type='text'], textarea {
	-webkit-appearance: none; // make iOS inputs pretty
}

input.autocomplete {
	&:autofill,
	&:autofill:hover,
	&:autofill:focus,
	&:autofill:active {
		font-size: $font-size;
		color: mix($color6, $color3, 80%) !important;
		background: $color1-lightest !important;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: mix($color6, $color3, 80%);
		-webkit-box-shadow: 0 0 0 32px $color1-lightest inset !important;
		transition: background-color 5000s ease-in-out 0s;
		border-radius: 4px !important;
	}
	&:-webkit-autofill::first-line {
		// font-size: $font-size !important;
		font-family: $font-text !important;
	}
}
