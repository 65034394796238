html, body {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

body {
	font-variant-ligatures: none;
	font-family: $font-text;
	font-size: $font-size;
	color: $color4;
	background-color: $color1;
	margin: 0;
	line-height: 125%;
}

* {
	box-sizing: border-box;
}

main {
	display: block;
}

a {
	text-decoration: none;
	color: inherit;
}

a.link {
	color: $color6;
	transition: color 0.2s;
	&:hover {
		color: lighten($color6, 8%);
	}
}

i {
	font-style: italic;
}

button {
	font-family: $font-text;
}
